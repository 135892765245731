/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';

import { rootDomain, cookieDomain, ExternalLinks, buildExternalURL } from '@mirinae/js-utils';

// pull in each apps local leaf paths (used in their local <Route>ing) to build absolute paths for every URL in every app, for sharing
import { localPaths as explorerLocalPaths } from '@mirinae/explorer/modules/defines/paths';
import { localPaths as membersLocalPaths } from '@mirinae/members/modules/defines/paths';
import { localPaths as lessonsLocalPaths } from '@mirinae/lessons/modules/defines/paths';
import { localPaths as wikinaeLocalPaths } from '@mirinae/wikinae/modules/defines/paths';
import { localPaths as lyricExplorerLocalPaths } from '@mirinae/lyric-explorer/modules/defines/paths';
import { localPaths as authLocalPaths } from '@mirinae/auth/modules/defines/paths';
import { localPaths as glossaryLocalPaths } from '@mirinae/glossary/modules/defines/paths';
import { localPaths as feedbackLocalPaths } from '@mirinae/feedback/modules/defines/paths';

export { rootDomain, cookieDomain, ExternalLinks, buildExternalURL };

export var apiServer = '';  // always use app server host for now (will proxy to localhost:2005 in local dev server)

// the apps' root paths
export const appRoots = {
    explorer: 'explorer',
    lessons: 'lessons',
    members: 'members',
    wikinae: 'wikinae',
    lyricExplorer: 'lyric-explorer',
    auth: 'auth',
    // glossary: 'glossary',  // these are not to be used for now, they have no GNB or footer, use app-local versions
    // feedback: 'feedback',  //    "   "
};

// construct full paths for all pages across all app
export const explorerPaths = Object.entries(explorerLocalPaths)
    .reduce((m, [pathKey, localPath]) => { m[pathKey] = `/${appRoots.explorer}${localPath ? '/' : ''}${localPath}`; return m; }, { });
export const lessonsPaths = Object.entries(lessonsLocalPaths)
    .reduce((m, [pathName, localPath]) => { m[pathName] = `/${appRoots.lessons}${localPath ? '/' : ''}${localPath}`; return m; }, { });
export const membersPaths = Object.entries(membersLocalPaths)
    .reduce((m, [pathName, localPath]) => { m[pathName] = `/${appRoots.members}${localPath ? '/' : ''}${localPath}`; return m; }, { });
export const wikinaePaths = Object.entries(wikinaeLocalPaths)
    .reduce((m, [pathName, localPath]) => { m[pathName] = `/${appRoots.wikinae}${localPath ? '/' : ''}${localPath}`; return m; }, { });
export const lyricExporerPaths = Object.entries(lyricExplorerLocalPaths)
    .reduce((m, [pathName, localPath]) => { m[pathName] = `/${appRoots.lyricExplorer}${localPath ? '/' : ''}${localPath}`; return m; }, { });
export const authPaths = Object.entries(authLocalPaths)
    .reduce((m, [pathName, localPath]) => { m[pathName] = `/${appRoots.auth}${localPath ? '/' : ''}${localPath}`; return m; }, { })
export const glossaryPaths = Object.entries(glossaryLocalPaths)
    .reduce((m, [pathName, localPath]) => { m[pathName] = `/${appRoots.glossary}${localPath ? '/' : ''}${localPath}`; return m; }, { })
export const feedbackPaths = Object.entries(feedbackLocalPaths)
    .reduce((m, [pathName, localPath]) => { m[pathName] = `/${appRoots.feedback}${localPath ? '/' : ''}${localPath}`; return m; }, { })

// external link redirector component
export const ExternalRedirector = ({ to, reload = false, versionParam }) => {
    const url = buildExternalURL(to, versionParam);
    useEffect(() => {
        if (reload)
            window.location.href = url;
        else
            window.location.replace(url);
    }, []);
    return null;
};

export const navigateExternal = (_url, options = {}) => {
    const url = buildExternalURL(_url, options.versionParam);
    if (options.replace) {
        window.location.replace(url);
    } else {
        window.location.href = url;
    }
};

// ad-hoc mapping of certain old /#/ URLs, as needed for new URL design
const pathMapping = (origApp, origPath, origQuery) => {
    let path = `${origApp}${origApp ? '/' : ''}${origPath}`;
    let query = origQuery.replace(/\/$/, '');
	// new root paths (independent of app)
    const newRoot = {
        'login': 'auth/login',
        'signup': 'auth/signup',
        'reset-password': 'auth/reset-password',
        'email-signup': 'auth/email-signup',
        'withdraw': 'auth/withdraw',
        'account-confirmed': 'auth/account-confirmed',
        // 'userFeedback': 'feedback',  don't share yet
    }[origPath];
    if (newRoot) {
        path = newRoot;
    } else if (origApp === 'explorer') {
        // fix incorrect mobile app URLs (not subdomain-based: https://app.mirinae.dev/?v=2.1.5/#/lessons)
        const newPath = {
            'lessons': 'lessons',
            'profile': 'members/profile',
            'library': 'explorer/library',
            'glossary': 'explorer/glossary',
        }[origPath];
        if (newPath) {
            path = newPath;
        }
    } else {
        // new root paths by app
        const newRootByApp = {
            // 'explorer/glossary': 'glossary', don't share yet, need to refactor header & footer to be app-independent
        }[path];
        if (newRootByApp) {
            path = newRootByApp;
        }
    }
    if (path.endsWith('.')) path += '/'; // wikinae. hack
	return [path, query];
};


// handle old /#/... urls via a server redirect
export const redirectHashURLs = (isApp, defaultHome) => {
      
    /*
    if (location.hash.startsWith('#/') || (location.hostname.split('.').length > 2 && !location.hostname.match(/^(alpha|local)\./))) {
        // parse hostname, which may include any of our original subddomains, ignoring any alpha
        // or app, to gen the new top-level, app path elemnents
        
        let rootDomain = location.hostname.includes('.') ? location.hostname.match(/((.+\.)|(^))([a-z\-]+\.[a-z][a-z][a-z]*)$/)[4] : location.hostname;
        const alpha = location.hostname.startsWith('alpha.') ? 'alpha.' : '';
        const local = location.hostname.startsWith('local.') ? 'local.' : '';
        const baseHostName = location.hostname.replace(/(alpha\.)|(app\.)|(alpha\.app\.)|(local\.)/, '');
        let app = baseHostName.replace(new RegExp(`[.]?${rootDomain}$`), '') || 'explorer'; // for now, app path prefix from #-url subdomain
        app = app.replace(/app\.?/, '');
        if (isApp && app === 'wikinae') app = 'wikinae.';  // hack so old mobile apps will display wikinae pages correctly (they search for 'wikinae.' to condition screen for wikinae!)
        const port = location.port ? `:${location.port}` : '';
        // extract hash-component as rest of path
        const [, origPath, origQuery] = location.hash ? location.hash.match(/^#\/(.*?)($|(\?.*$))/) : ['', '', location.search]; // this is the original pathing, we may transform it at some point further
        const [path, query] = pathMapping(app, origPath, origQuery || location.search); // allow for any re-mapping for new pathing
        const lang = ''; // '/en'; // for now, get it from Localization when shared
        const url = `${location.protocol}//${alpha}${local}${rootDomain}${port}${lang}/${path}${query || ''}`;

        console.log('redirect', location.href, 'to', url);
        // assign to location.href to force a server visit for SEO purposes
        window.location.href = url;
        return null;
    }

        */
    return defaultHome;
}

// this is depracacted, one of the original path namespaces from the separated apps (Explorer in this case)
export const pagePaths = {
    root: '/',
    home: '/',
    // gate: '/gate',
    reference: '/reference',
    library: '/library',
    glossary: '/glossary',
    help: '/help',
    login: '/login',
    signup: '/signup',
    emailSignup: '/email-signup',
    resetPassword: '/reset-password',
    history: '/explorer/history',
    // bookmark: '/bookmark',
    // setting: '/setting',
    withdraw: '/withdraw',
    // welcome: '/welcome',
    landing: '/landing',
    tutorial: '/tutorial',
    // show404: '/404.html',
    // oldBrowser: '/oldbrowser.html',
    userFeedback: '/userFeedback',
    feedback: '/user-feedback',
    ocr: '/ocr',
    accountConfirmed: '/account-confirmed/:signupBy',
    wikinae: '/wikinae',

    contents: '/lyrics',
    artists: '/artists',
    artist: '/artist',
    music: '/music',
    song: '/song',

    lessons: '/lessons',
    features: '/features',
    myLesson: '/lessons',
    myLessons: '/lessons',
    lessonPlayer: '/lessons/player',
    lessonsFeatures: '/lessons/features',
    lessonsSurvey: '/lessons/survey',

    plan: '/plan',
    myAccount: '/profile',
    pro: '/members/',
    payment: '/members/payment',
    profile: '/members/profile',
    wikinaeHome: '/wikinae/',

    // wikinae
    wikinaeEntry: '/wikinae/entry',
};


export const apiPaths = {
    check: '/api/auth/check',
    localLogin: '/api/auth/login/local',
    guestSignup: '/api/auth/signup/guest',
    localSignup: '/api/auth/signup/local',
    googleLogin: '/api/auth/login/google',
    facebookLogin: '/api/auth/login/facebook',
    twitterLogin: '/api/auth/check/twitter',
    logout: '/api/auth/logout',
    sendMail: '/api/auth/send-mail',
    verifyOpt: '/api/auth/verify-opt',
    reset: '/api/auth/reset-password',
    deleteAccount: '/api/auth/delete',
    updatePrefs: '/api/user/prefs/update',
    appleLogin: '/api/auth/mobile/apple', // piggy-back on mobile apple login, same response & processing for web

    // user notes
    saveNote: '/api/notes/save-note',
    deleteNote: '/api/notes/delete-note',
    listNotes: '/api/notes/list',
    // user feedback
    sendFeedback: '/api/feedback/send-feedback',
    // exploration history   **** note that for now these are directed to the NLP back-end server, should be handled by front-end-server in time ***
    bookmark: '/api/nlp/bookmark',
    listHistory: '/api/nlp/hist/list',
    patternHistory: '/api/nlp/hist/for-pattern',
    getBookmark: '/api/nlp/bookmark/get',
    deleteBookmark: '/api/nlp/bookmark/delete',
    deleteAllBookmarks: '/api/nlp/bookmark/delete-all',
    // reference library
    versionCheck: '/api/nlp/reference/check',
    listReferences: '/api/nlp/reference/list',
    getSource: '/api/nlp/source/get',

    // reward access keys
    accessFeatures: '/api/user/key/accessFeatures',
    grantKey: '/api/user/key/grantKey',
    listActiveAdUnits: '/api/user/key/listActiveAdUnits',

    // glossary library
    listGlossary: '/api/nlp/glossary/list',

    // try these sentence
    tryThese: '/api/sentence/list-try?client=true',
    tryWikinae: '/api/sentence/todays-wikinae',

    // withdrawal
    withdrawalSurvey: '/api/withdrawal/list-survey?client=true',

    getOcr: '/api/ocr/get',

    // threads board
    addPost: '/api/social/post/add', // POST - adds a new user post
    getPost: '/api/social/post/get', // GET - /api/social/post/get/:postID - retrieves retrieves IDed post 
    updatePost: '/api/social/post/update', // POST - /api/social/post/update/:postID  - update post 
    deletePost: '/api/social/post/delete', // POST - /api/social/post/delete/:postID - delete a post (marks as deleted in status) 
    votePost: '/api/social/post/vote', // POST - /api/social/post/vote/:postID - vote a post up or down 
    report: '/api/social/post/report', // reports possible bad post 
    reportUpdate: '/api/social/post/report/update', // update a report
    postList: '/api/social/post/list', // GET - get list of postings under filter, sort & pagination
    // '/api/social/post/reports',  // get list of post reports under filter, sort & pagination  /api/social/tag/set       // set tags on a given object
    // '/api/social/tag/list',      // list objects tagged with tag, w/filter, sort & pagination
    like: '/api/social/like', // POST - short-cut to add #like tagging to an object
    unlike: '/api/social/unlike', // POST - short-cut to remove #like tagging to an object  
    reportReasonList: '/api/social/report/reasons/list?client=true',

    // profile
    updateName: '/api/user/name/update',
    updateEmail: '/api/user/email/update',
    updateProfileImage: '/api/user/profile/image/update',

    // paypal payment
    addSubscription: '/api/payment/paypal/addsubscription',
    cancelSubscription: '/api/payment/paypal/cancelsubscription',

    // promotion
    promotionList: '/api/promotion/read',

    sendVerification: '/api/auth/signup/send-verification',
    // check: '/api/auth/check',

    checkLocalEmail: '/api/auth/check/local-email',

    // wikinae entries
    entryList: '/api/wikinae/entry/list',
    entryView: '/api/wikinae/entry/view',
    entryBookmark: '/api/wikinae/entry/bookmark', // add or delete a bookmark
    bookmarks: '/api/wikinae/bookmarks', // lists user's bookmark
    search: '/api/wikinae/search',
    searchterms: '/api/wikinae/searchterms',
    usersearchterms: '/api/wikinae/usersearchterms',
    clearusersearchterms: '/api/wikinae/usersearchterms/clear',

    // lesson player API
    lessonList: '/api/lessons/list', // POST - get list of availabel lessons (no search or pagination support yet)
    getMyLessons: '/api/lessons/getMyLessons', // POST - get a list of lessons I have taken. in activity-date-reverse order, including progress %
    getLesson: '/api/lessons/getLesson', // GET /api/lessons/getLesson/:lessonID - get identified lesson object
    getLessonProgress: '/api/lessons/progress', // GET /api/lesson/progress/:lessonID - request latest progress on objectIDed lesson
    postEvent: '/api/lessons/postEvent', // POST /api/lesson/event/:lessonID - post lesson user event on objectIDed lesson
    resetProgress: '/api/lessons/resetProgress', // POST /api/lesson/resetProgress/:lessonID - reset progress on objectIDed lesson
    elementImage :'/api/lessons/element/image', // GET /api/lessons/element/image/:figmaKey' - retrieves element with given figmaKey
    gatherFigmaAssets: '/api/lessons/gatherfigmaassets',
    getLessonsList: '/apt/lessons/list',
    topLessons: '/api/lessons/toplessons', // get top-ranked lessons
    viewCounts: '/api/lessons/viewcounts',
    graphicGet: '/api/lessons/graphic/get',

    // lesson editor APIs
    updateNodeMap: '/api/lessons/updatenodemap',
    updateNodeMapByGuid: '/api/lessons/updatenodemapbyguid',
    lessonsByFigmaID: '/api/lessons/lessonsbyfigmaid',
    lessonsForEditor: '/api/lessons/lessonsforeditor',
    snapshot: '/api/lessons/snapshot',
    backup: '/api/lessons/backup',
    listBackups: '/api/lessons/listbackups',
    getBackup: '/api/lessons/getBackup',
    login: '/api/auth/login/local',
    getFigmaFileJSON: '/api/lessons/getfigmafilejson',
    saveClipboard: '/api/lessons/saveclipboard',
    getClipboard: '/api/lessons/getclipboard',
    export: '/api/lessons/export',
    import: '/api/lessons/import',
    editLock: '/api/lessons/editlock',
    lesson: '/api/lessons/lesson',
    globalSearch: '/api/lessons/globalsearch',
    lessonSearch: '/api/lessons/search',
    getLessonSpec: '/api/lessons/spec',
    postSpec: '/api/lessons/spec',
    updateLessonSet: '/api/lessons/updateset',
    updateLessonFlag: '/api/lessons/updateflag',
    assignEditor: '/api/lessons/assigneditor',
    graphicUpload: '/api/lessons/graphic/upload',

    // NLP API used by lessons editor
    setLesson: '/api/nlp/pattern/setlesson',
    getPageOfPatterns: '/api/nlp/pattern/getpageof',
    getPattern: '/api/nlp/pattern/get/',

    // old lessons API
    createLesson: '/api/lessons/create',
    createPage: '/api/lessons/page/create',
    updateDB: '/api/lessons/updatedb',

};
